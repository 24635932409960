import React from "react"
// import { Link } from "gatsby"
import {Carousel, Row, Col} from 'react-bootstrap'
import Layout from "../components/layout"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  {graphql, Link} from 'gatsby'
import {Helmet} from 'react-helmet'

var settings = {
     dots: false,
     arrows:true,
     infinite: true,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     centerMode: true,
     centerPadding: 0
   };

class IndexPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      instagram:[]
    }
  }

   componentDidMount() {
      var apiurl = `https://www.instagram.com/thebeautysyrup/?__a=1`

      fetch(apiurl)
      .then(data => data.json())
      .then(data => {

        this.setState({instagram:data.graphql.user.edge_owner_to_timeline_media.edges})
      })
   }

  render() {
    return (
      <Layout searcharray={this.props.pageContext.searcharray} path={this.props.path} menu={this.props.pageContext.menu}>
        <Helmet>
          {this.props.pageContext.metainfo.map((item,i) => {
    if(item.key === 'title') {
      return (
        <title key={i}>{item.value}</title>
      )
    }
    else if(item.key === 'canonical') {
      return (
        <link key={i} rel="canonical" href={this.props.path} />
      )
    }

    else if(item.key === 'description') {
      return (
        <meta key={i} name="description" content={item.value}/>
      )
    }

    else {
      return null;
    }
  })}
        </Helmet>
        <section>
         <section className="carouselcontainer1">
        <Carousel fade={false} controls={true} touch={true}>
          {this.props.data.drupal.nodeQuery.entities.map((item)=> {
            return (
              <Carousel.Item key={item.entityId}>
                <Row noGutters={true}>
                  <Col xs={12} md={8}>
                    <Link to={item.entityUrl.path}>
                    <img
                    style={{minHeight:'300px'}}
                    className="d-block w-100 h-100"
                    src={item.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fluid.src}
                    alt={item.fieldMainImage.entity.thumbnail.alt}
                  />
                  </Link>

                </Col>
                <Col className="sliderhomecol p-3" xs={12} md={4}>
                  <div className="p-2 carousel-content h-100 d-flex justify-content-center align-items-center flex-column text-white">
                        <Link className="text-white" to={item.entityUrl.path}><h3 className="text-center mb-3">{item.entityLabel}</h3></Link>



                  </div>

                </Col>

                </Row>
              </Carousel.Item>

            )
          })}


    </Carousel>
    </section>

    <section className="slicker">
      <h2 className="slickertitle text-center mb-4">Instagram</h2>
      <Slider initialSlide={1} arrows={false} responsive={[
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,

            infinite: true,

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,

          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]} {...settings} >

           {this.state.instagram.map((item) => {
             return (
               <div key={item.id}>
                 <img
                   className=""
                   src={item.node.thumbnail_resources[2].src}
                   alt="Third slide"
                 />
               </div>
             )
           })}






          </Slider>

    </section>

    <section className="tabs">
      <h2 className="slickertitle text-center">More on the blog</h2>
      <Row noGutters={true}>
         {this.props.data.drupal.last.entities.map((item)=> {
           return (

             <Col key={item.entityId} className="py-md-3 px-md-3 py-3 px-0"  md={6}>

                <div className="position-relative">
               <div className="opacitylayer"></div>

               <img
                 style={{minHeight:'300px'}}
                 className="w-100"
                 src={item.fieldMainImage.entity.thumbnail.imageFile.childImageSharp.fluid.src}
                 alt={item.fieldMainImage.entity.thumbnail.alt}
               />

             <div className="innertab d-flex justify-content-center align-items-center flex-column text-white">
                     <Link className="text-white" to={item.entityUrl.path}><h3 className="text-center mb-3">{item.entityLabel}</h3></Link>



               </div>
               </div>
             </Col>

           )

         })}



      </Row>
    </section>

    </section>


      </Layout>
    )


  }
}




export default IndexPage

export const query = graphql`
query {
  drupal {
    nodeQuery(filter: {conditions: [{field: "type", value: "blog", operator: EQUAL}, {field: "sticky", value: "", operator: NOT_EQUAL}]}, limit: 4) {
      entities {
        ... on DRUPAL_NodeBlog {
          entityLabel
          entityId
          entityUrl {
            path
          }
          sticky
          fieldMainImage {
            entity {
              thumbnail {
                url
                alt
                imageFile {
                  childImageSharp {
                    fluid(fit: COVER, maxWidth: 700, maxHeight: 500, quality: 90) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    last:nodeQuery(filter: {conjunction: AND, conditions: [{field: "type", value: "blog"}, {field: "sticky", value: "1", operator: NOT_EQUAL}]}, limit: 4, sort: [{field: "created"}]) {
entities {
  ... on DRUPAL_NodeBlog {
    entityLabel
    entityId
    entityUrl {
      path
    }
    sticky
    fieldMainImage {
      entity {
        thumbnail {
          url
          alt
          imageFile {
            childImageSharp {
              fluid(fit: COVER, maxWidth: 700, quality: 90, maxHeight: 500) {
                src
              }
            }
          }
        }
      }
    }
  }
}
}

  }
}

`
